(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("material-ui"), require("material-ui"), require("material-ui"), require("material-ui"), require("material-ui"), require("material-ui"), require("ReactDOM"), require("material-ui"), require("material-ui"), require("Moment"), require("material-ui"), require("material-ui"), require("material-ui"), require("material-ui"), require("material-ui"), require("material-ui"), require("material-ui"), require("material-ui"), require("material-ui"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "material-ui", "material-ui", "material-ui", "material-ui", "material-ui", "material-ui", "ReactDOM", "material-ui", "material-ui", "Moment", "material-ui", "material-ui", "material-ui", "material-ui", "material-ui", "material-ui", "material-ui", "material-ui", "material-ui"], factory);
	else if(typeof exports === 'object')
		exports["Material_UI_Pickers"] = factory(require("React"), require("material-ui"), require("material-ui"), require("material-ui"), require("material-ui"), require("material-ui"), require("material-ui"), require("ReactDOM"), require("material-ui"), require("material-ui"), require("Moment"), require("material-ui"), require("material-ui"), require("material-ui"), require("material-ui"), require("material-ui"), require("material-ui"), require("material-ui"), require("material-ui"), require("material-ui"));
	else
		root["PSLIB"] = root["PSLIB"] || {}, root["PSLIB"]["React"] = root["PSLIB"]["React"] || {}, root["PSLIB"]["React"]["Material_UI_Pickers"] = factory(root["PSLIB"]["ReactCommon"]["React"], root["PSLIB"]["React"]["Material_UI"]["styles"], root["PSLIB"]["React"]["Material_UI"]["Typography"], root["PSLIB"]["React"]["Material_UI"]["Button"], root["PSLIB"]["React"]["Material_UI"]["SvgIcon"], root["PSLIB"]["React"]["Material_UI"], root["PSLIB"]["React"]["Material_UI"]["IconButton"], root["PSLIB"]["ReactCommon"]["ReactDOM"], root["PSLIB"]["React"]["Material_UI"]["TextField"], root["PSLIB"]["React"]["Material_UI"]["Tab"], root["PSLIB"]["Moment"], root["PSLIB"]["React"]["Material_UI"]["styles"]["colorManipulator"], root["PSLIB"]["React"]["Material_UI"]["Toolbar"], root["PSLIB"]["React"]["Material_UI"]["DialogActions"], root["PSLIB"]["React"]["Material_UI"]["DialogContent"], root["PSLIB"]["React"]["Material_UI"]["Dialog"], root["PSLIB"]["React"]["Material_UI"]["Popover"], root["PSLIB"]["React"]["Material_UI"]["InputAdornment"], root["PSLIB"]["React"]["Material_UI"]["Tabs"], root["PSLIB"]["React"]["Material_UI"]["Paper"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__21__, __WEBPACK_EXTERNAL_MODULE__29__, __WEBPACK_EXTERNAL_MODULE__30__, __WEBPACK_EXTERNAL_MODULE__31__, __WEBPACK_EXTERNAL_MODULE__48__, __WEBPACK_EXTERNAL_MODULE__50__, __WEBPACK_EXTERNAL_MODULE__68__, __WEBPACK_EXTERNAL_MODULE__69__, __WEBPACK_EXTERNAL_MODULE__95__, __WEBPACK_EXTERNAL_MODULE__96__, __WEBPACK_EXTERNAL_MODULE__97__, __WEBPACK_EXTERNAL_MODULE__98__, __WEBPACK_EXTERNAL_MODULE__99__, __WEBPACK_EXTERNAL_MODULE__100__, __WEBPACK_EXTERNAL_MODULE__101__, __WEBPACK_EXTERNAL_MODULE__102__, __WEBPACK_EXTERNAL_MODULE__103__, __WEBPACK_EXTERNAL_MODULE__104__) {
return 